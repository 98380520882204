import romanApi, { type RomanApiError } from "@roman/shared/lib/net/romanApi";
import type { RoundDetails } from "@roman/shared/types/RoundDetails";
import { useEffect } from "react";
import useSWR, { type KeyedMutator } from "swr";
import type { Language, LanguageQuery } from "../../types/Language";
import { type AuthHeader, useAuthHeader } from "../useAuthHeader";

export interface RoundResponse {
  round: RoundDetails;
}

interface UseSoccerRoundReturn {
  data?: { round: RoundDetails };
  isLoading: boolean;
  mutate: KeyedMutator<RoundResponse>;
}

const fetcher = (url: string, headers: AuthHeader, lang: Language) =>
  romanApi.get<RoundResponse, LanguageQuery>(url, { lang }, { headers });

export const useSoccerRound = (
  roundId: string,
  handleError: (err: RomanApiError) => void,
  lang: Language = "en",
): UseSoccerRoundReturn => {
  const header = useAuthHeader();
  const { data, error, isValidating, mutate } = useSWR<RoundResponse, RomanApiError>(
    roundId ? `/soccer/rounds/${roundId}/` : null,
    (url: string) => fetcher(url, header, lang),
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error, handleError]);

  const homePlayers = data?.round.players.homePlayers ?? [];

  const awayPlayers = data?.round.players.awayPlayers ?? [];

  return {
    data: data
      ? {
          round: {
            ...data.round,
            players: { homePlayers, awayPlayers },
          },
        }
      : undefined,
    isLoading: roundId ? !data && isValidating : false,
    mutate,
  };
};
